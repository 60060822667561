<template>
  <div>
    <div class="wrapper">
      <div class="mb-1 d-flex w-100 justify-space-between">
        <span class="label">{{ label }}</span>
        <div class="mr-1">
          <v-btn
            small
            class="px-4"
            @click="unselectAllPermissions"
            color="primary"
            icon
          >
            <v-icon size="20">
              mdi-checkbox-multiple-blank-outline
            </v-icon>
          </v-btn>
          <v-btn
            small
            class="px-4"
            @click="selectAllPermissions"
            color="primary"
            icon
          >
            <v-icon size="20">
              mdi-checkbox-multiple-marked-outline
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-for="permission in permissions"
        :key="permission.id"
        class="d-flex justify-space-between mb-1"
      >
        <span>{{ permission.displayName }}</span>
        <v-checkbox
          :value="isPermissionsSelected(permission)"
          hide-details
          class="mt-0"
          @click="selectPermission(permission)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    permissions: {
      type: Array,
      default: () => []
    },
    selectedPermissions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isPermissionsSelected(permission) {
      return this.selectedPermissions.map(p => p.name).includes(permission.name)
    },
    // add or delete permission from selectedPermissions by clicking checkbox
    selectPermission(permission) {
      const index = this.selectedPermissions.findIndex(p => p.name === permission.name)
      if (index === -1) {
        // add permission to selectedPermissions
        this.$emit('update', [...this.selectedPermissions, permission])
      } else {
        // delete permission from selectedPermissions
        this.$emit(
          'update',
          this.selectedPermissions.filter(p => p.name !== permission.name)
        )
      }
    },
    selectAllPermissions() {
      this.$emit('update', this.permissions)
    },
    unselectAllPermissions() {
      this.$emit('update', [])
    }
  }
}
</script>

<style scoped>
.wrapper {
  background-color: #2cb56b1c;
  border-radius: 4px;
  padding: 8px 8px 12px 16px !important;
  width: 100%;
}

.label {
  color: black;
  font-weight: 700;
  font-size: 12px;
}
</style>
