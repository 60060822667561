<template>
  <div class="mt-n9">
    <PermissionsTable
      label="Dostępy do widoków"
      :permissions="viewPermissions"
      :selected-permissions="rolePermissions"
      @update="setRolePermissions"
    />
    <PermissionsTable
      class="mt-2"
      label="Dostępy do ustawień"
      :permissions="settingsPermissions"
      :selected-permissions="rolePermissions"
      @update="setRolePermissions"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import inputMixin from '../../../mixins/InputMixin.vue'
import PermissionsTable from '../Elements/PermissionsTable.vue'

export default {
  mixins: [inputMixin],
  components: {
    PermissionsTable
  },
  data() {
    return {
      rolePermissions: []
    }
  },
  mounted() {
    this.rolePermissions = this.data.defaultPermissions || []
  },
  computed: {
    ...mapState({
      allPermissions: state => state.core.filters.permissions
    }),
    viewPermissions() {
      return this.allPermissions.filter(p => p.permissionType === 'Widoki')
    },
    settingsPermissions() {
      return this.allPermissions.filter(p => p.permissionType === 'Ustawienia')
    }
  },
  methods: {
    setRolePermissions(rolePermissions) {
      this.rolePermissions = rolePermissions
      this.updateValues()
    },
    updateValues() {
      this.$emit('update', {
        permissionIds: this.rolePermissions.map(p => p.id)
      })
    }
  }
}
</script>
